<template>
  <v-dialog v-model="showDialog" v-show="room" class="ma-0 pa-0" :width="$vuetify.breakpoint.smAndUp ? '688px' : '95%'">
    <div class="dialog-content text-center">
      <template>
        <h2>{{ $t("room_info.message_retention") }}</h2>
      </template>
      <v-container fluid>
        <v-row cols="12">
          <v-col cols="12">
            <v-form v-model="isValid">
              <v-radio-group v-model="retention" class="my-0 py-0">
                <v-radio active-class="radio-active" class="flex-row-reverse mb-0" v-for="p in retentionPeriods"
                  :key="p.text" :label="p.text" :value="p.value" />
              </v-radio-group>
            </v-form>
          </v-col>
        </v-row>
        <v-row cols="12" class="justify-center mt-0">
          <v-col cols="4" class="py-0">
            <v-btn color="primary" :disabled="!isValid" depressed block class="filled-button my-0"
              @click.stop="setRetention()">{{
                $t("menu.done") }}</v-btn>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </v-dialog>
</template>
<script>
import roomInfoMixin from "./roomInfoMixin";

export default {
  name: "MessageRetentionDialog",
  mixins: [roomInfoMixin],
  props: {
    show: {
      type: Boolean,
      default: function () {
        return false;
      },
    },
    initialValue: {
      type: Number,
      default: function () {
        return 0;
      },
    },
  },
  data() {
    return {
      showDialog: false,
      isValid: true,
      retention: 0,
      retentionMinValue: 60,
      retentionMaxValue: 60 * 60 * 24 * 500
    };
  },
  watch: {
    show: {
      handler(newVal, ignoredOldVal) {
        this.showDialog = newVal;
      },
    },
    showDialog(val, oldVal) {
      if (!val && oldVal) {
        this.$emit("close");
      } else if (val && !oldVal) {
        // Showing, reset
        this.setInitialValue();
      }
    }
  },
  mounted() {
    this.setInitialValue();
  },
  methods: {
    setInitialValue() {
      this.isValid = true;
      if (this.room) {
        this.retention = this.roomMessageRetention();
      } else {
        this.retention = this.initialValue;
      }
    },
    setRetention() {
      let ms = this.retention;
      if (this.room) {
        if (ms === 0 || !ms) {
          // No expiry
          this.$matrix.matrixClient.sendStateEvent(
            this.room.roomId,
            "m.room.retention",
            { max_lifetime: 0 }
          );
        } else if (ms >= 1000 * this.retentionMinValue && ms <= 1000 * this.retentionMaxValue) {
          this.$matrix.matrixClient.sendStateEvent(
            this.room.roomId,
            "m.room.retention",
            { max_lifetime: ms }
          );
        }
      }
      this.$emit('message-retention-update', ms)
      this.showDialog = false;
    }
  },
};
</script>

<style lang="scss">
@import "@/assets/css/chat.scss";

.v-radio .other {
  display: flex;
  min-height: 80px;
}

.v-radio .v-text-field {
  display: none;
  margin-left: 8px;
  min-width: 200px;
}

.v-radio.radio-active {
  .v-text-field {
    display: inline-flex;
  }
}

.v-radio.flex-row-reverse {
  height: $min-touch-target;
}
</style>
