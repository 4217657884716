<template>
  <div class="create-root fill-height">
    <div class="mt-4 d-flex flex-row align-center">
      <v-btn id="btn-back" text class="back-button" v-show="$navigation && $navigation.canPop()" @click.stop="goBack">
        <v-icon>arrow_back</v-icon>
      </v-btn>
      <YouAre class="flex-grow-1 text-end" v-if="loggedIn" />
    </div>
    <div class="mt-40 text-center px-4">
      <div class="create-title no-icon h3-bold">{{ $t("create.title") }}</div>
      <div class="room-type" @click="createRoom" v-if="$config.roomTypes.includes('group_chat')">
        <div class="room-type__icon">
          <v-icon>$vuetify.icons.room_type_room</v-icon>
        </div>
        <div class="room-type__name">{{ $t("create.room_type_room_name") }}</div>
        <div class="room-type__description">{{ $t("create.room_type_room_description") }}</div>
      </div>
      <div class="room-type" @click="createChannel" v-if="$config.roomTypes.includes('channel')">
        <div class="room-type__icon">
          <v-icon>$vuetify.icons.room_type_channel</v-icon>
        </div>
        <div class="room-type__name">{{ $t("create.room_type_channel_name") }}</div>
        <div class="room-type__description">{{ $t("create.room_type_channel_description") }}</div>
      </div>
      <div class="room-type" @click="createFiledrop" v-if="$config.roomTypes.includes('file_drop')">
        <div class="room-type__icon">
          <v-icon>$vuetify.icons.room_type_filedrop</v-icon>
        </div>
        <div class="room-type__name">{{ $t("create.room_type_filedrop_name") }}</div>
        <div class="room-type__description">{{ $t("create.room_type_filedrop_description") }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import YouAre from "../components/YouAre.vue";

export default {
  name: "Create",
  components: { YouAre },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
  },
  methods: {
    goBack() {
      this.$navigation.pop();
    },
    createRoom() {
      this.$navigation.push({ name: "CreateRoom" });
    },
    createChannel() {
      this.$navigation.push({ name: "CreateChannel" });
    },
    createFiledrop() {
      this.$navigation.push({ name: "CreateFileDrop" });
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/css/typography.scss";
@import "@/assets/css/create.scss";

.create-title {
  margin-bottom: 24 * $chat-text-size;
}

.room-type {
  padding: 15 * $chat-text-size;
  margin-bottom: 12 * $chat-text-size;
  box-sizing: border-box;
  position: relative;
  user-select: none;

  &::after {
    content: " ";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 8 * $chat-text-size;
    border: 1px solid #ededed;
  }

  &:active {
    background: #efeffe;
  }

  &:hover::after {
    border: 2px solid $very-very-purple;
  }

  &:active::after {
    border: 1px solid $poll-hilite-color;
  }

  .room-type__icon svg {
    color: transparent;
  }
  &:hover .room-type__icon svg, &:active .room-type__icon svg {
    color: $very-very-purple;
  }

  .room-type__name {
    color: black;
    font-family: Inter;
    font-size: 16 * $chat-text-size;
    font-weight: 600;
    line-height: 18.72 * $chat-text-size;
    letter-spacing: 0.40 * $chat-text-size;
    text-align: center;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    margin-top: 8 * $chat-text-size;
    margin-bottom: 6 * $chat-text-size;
  }

  .room-type__description {
    color: rgba(0, 0, 0, 0.65);
    font-family: Inter;
    font-size: 13 * $chat-text-size;
    font-weight: 400;
    line-height: 15.21 * $chat-text-size;
    letter-spacing: 0.40 * $chat-text-size;
    text-align: center;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
  }
}
</style>