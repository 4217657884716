import { render, staticRenderFns } from "./YouAre.vue?vue&type=template&id=a56f1c20&"
import script from "./YouAre.vue?vue&type=script&lang=js&"
export * from "./YouAre.vue?vue&type=script&lang=js&"
import style0 from "./YouAre.vue?vue&type=style&index=0&id=a56f1c20&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VChip } from 'vuetify/lib/components/VChip';
installComponents(component, {VAvatar,VChip})
