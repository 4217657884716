<template>
  <v-dialog v-model="showDialog" content-class="profile-info-popup" class="ma-0 pa-0">
    <v-card flat>
      <v-card-text>
        <div class="you-are">{{ $t("profile_info_popup.you_are") }}</div>
        <v-container fluid>
          <v-row>
            <v-col :class="['username', { 'editable': editDisplayName }]" cols="pa-2" ref="username">
              <div v-if="$matrix.currentUser.is_guest">
                <i18n path="profile_info_popup.identity_temporary" tag="span">
                  <template v-slot:displayName>
                    <input v-model="displayName"
                    @keyup.enter="$event => $event.target.blur()"
                    @blur="
                      updateDisplayName($event.target.value);
                    editDisplayName = !editDisplayName;
                                                                  " @focus="editDisplayName = !editDisplayName" />
                  </template>
                </i18n>
              </div>
              <div v-else>
                <i18n path="profile_info_popup.identity" tag="span">
                  <template v-slot:displayName>
                    <input
                      v-model="displayName"
                      @keyup.enter="$event => $event.target.blur()"
                      @blur="updateDisplayName($event.target.value);editDisplayName = !editDisplayName;"
                      @focus="editDisplayName = !editDisplayName"
                                                                  />
                  </template>
                </i18n>
              </div>
            </v-col>
            <v-col cols="auto" class="pa-2">
              <v-avatar class="avatar-32" size="32" color="#e0e0e0" @click.stop="viewProfile">
                <img v-if="userAvatar" :src="userAvatar" />
                <span v-else class="white--text">{{ userAvatarLetter }}</span>
              </v-avatar>
            </v-col>
          </v-row>
        </v-container>

        <div class="more-container">
          <div class="want_more">
            🙌 {{ $t("profile_info_popup.want_more") }}
          </div>
          <i18n path="profile_info_popup.powered_by" tag="div">
            <template v-slot:product>{{ product }}</template>
            <template v-slot:productLink>
              <a :href="'//' + productLink">{{ productLink }}</a>
            </template>
          </i18n>
          <div class="text-end" v-if="!$config.hide_add_room_on_home">
            <v-btn id="btn-new-room" class="new_room" text @click="createRoom">
              {{ $t("profile_info_popup.new_room") }}
            </v-btn>
          </div>
        </div>

        <a href="./ua.html" class="review-ua-link">{{ $t("profile_info_popup.review_ua") }}</a>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
import profileInfoMixin from "./profileInfoMixin";

export default {
  name: "ProfileInfoPopup",
  mixins: [profileInfoMixin],
  props: {
    show: {
      type: Boolean,
      default: function () {
        return false;
      },
    },
  },
  data() {
    return {
      showDialog: false,
      editDisplayName: false,
    };
  },
  computed: {
    product() {
      return this.$config.appName;
    },
    productLink() {
      return this.$config.productLink;
    },
  },
  watch: {
    show: {
      handler(newVal, ignoredOldVal) {
        this.showDialog = newVal;
      },
    },
    showDialog() {
      if (!this.showDialog) {
        this.$emit("close");
      }
    },
  },

  methods: {
    viewProfile() {
      this.showDialog = false;
      this.$navigation.push({ name: "Profile" }, 1);
    },
    createRoom() {
      this.showDialog = false;
      this.$navigation.push({ name: "Create" });
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/css/chat.scss";
@import '~vuetify/src/styles/settings/_variables.scss';

.profile-info-popup {
  font-family: "Inter", sans-serif !important;
  font-size: 16px;
  position: fixed;
  margin: 0px;
  top: 70px;
  right: 10px;

  [dir="rtl"] & {
    right: inherit;
    left: unset;
  }

  border-radius: 40px;
  width: 95%;

  .you-are {
    padding-top: 20px;
    font-size: 12px;
  }

  .username {
    border-radius: 4px;
    background-color: #f5f5f5;

    &.editable {
      background-color: unset;
      box-shadow: 0px 2px 6px rgb(0 0 0 / 8%);
    }

    input {
      width: 100%;

      &:focus {
        outline: none;
      }
    }
  }

  .more-container {
    border-radius: 10px;
    background-color: #f5f5f5;
    padding: 20px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14 * $chat-text-size;
    line-height: 117%;
    letter-spacing: 0.4px;
    color: #000B16;
    margin-top: 18px;
    a {
      color: #000B16 !important;
    }
    .want_more {
      font-family: "Poppins", sans-serif;
      font-weight: 700;
      font-size: 13 * $chat-text-size;
      color: #0e252d;
      margin-bottom: 9px;
    }

    .new_room {
      margin-top: 16px;
      height: 27px;
      border-radius: 13.5px;
      border: 1px solid #000000;
    }
    .new_room .v-btn__content {
      font-family: 'Inter', sans-serif;
      font-style: normal;
      font-weight: 700;
      font-size: 10 * $chat-text-size !important;
      line-height: 133%;
      letter-spacing: 0.34px;
      text-transform: uppercase;
      color: #181719;
    }
  }

  .review-ua-link {
    margin-top: 10px;
    text-decoration: none;
    color: #181719;
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 10 * $chat-text-size !important;
  }

  @media #{map-get($display-breakpoints, 'sm-and-up')} {
    width: 70%;
  }

  @media #{map-get($display-breakpoints, 'lg-and-up')} {
    overflow: unset;
    width: $main-desktop-width;
    position: absolute;
    top: 70px;
    right: unset;
    width: $dialog-desktop-width;

    &::before {
      position: absolute;
      top: -18px;
      right: 40px;
    }

    .v-card {
      border-radius: 20px;
    }
  }
}
</style>