export default {
  data() {
    return {
      retentionPeriods: [
        {
          text: this.$t("room_info.message_retention_none"),
          value: 0
        },
        {
          text: this.$t("room_info.message_retention_4_week"),
          value: 3600 * 24 * 28 * 1000
        },
        {
          text: this.$t("room_info.message_retention_2_week"),
          value: 3600 * 24 * 14 * 1000
        },
        {
          text: this.$t("room_info.message_retention_1_week"),
          value: 3600 * 24 * 7 * 1000
        },
        {
          text: this.$t("room_info.message_retention_1_day"),
          value: 3600 * 24 * 1000
        },
        {
          text: this.$t("room_info.message_retention_8_hours"),
          value: 3600 * 8 * 1000
        },
        {
          text: this.$t("room_info.message_retention_1_hour"),
          value: 3600 * 1000
        }
      ]
    };
  },
  methods: {
    /**
     * Get a string describing current room retention setting.
     * Can be "None", "1 week", "1 hour" etc...
     */
    roomMessageRetentionDisplayString(milliseconds) {
      const retentionPeriodFound = this.retentionPeriods.find(rp=>rp.value===milliseconds)
      if(retentionPeriodFound) {
        return retentionPeriodFound.text
      }
    },
  },
};