<template>
  <div class="create-root fill-height">
    <div class="mt-4 d-flex flex-row align-center">
      <v-btn id="btn-back" text class="back-button" v-show="$navigation && $navigation.canPop()" @click.stop="goBack"
        :disabled="creatingRoom">
        <v-icon>arrow_back</v-icon>
      </v-btn>
      <YouAre class="flex-grow-1 text-end" v-if="loggedIn" />
    </div>
    <div class="mt-40 px-4 text-center">
      <CreateRoomAvatar v-model="roomAvatar" />
      <div class="create-title h3-bold">{{ $t("createchannel.title") }}</div>
      <div class="create-info">{{ $t("createchannel.info") }}</div>
      <div color="rgba(255,255,255,0.1)" class="text-center">
        <v-form v-model="formIsValid">
          <v-text-field v-model="roomName" :label="$t('createchannel.channel_name')" v-bind="{ ...roomNameInputFields }"
            v-on="{ ...roomNameInputListeners }"></v-text-field>
          <v-text-field v-model="roomTopic" :label="$t('createchannel.channel_topic')"
            v-bind="{ ...roomTopicInputFields }" v-on="{ ...roomTopicInputListeners }"></v-text-field>
          <div class="caption-2 text-center mt-2 mb-8">{{ $t('createchannel.channel_topic_label') }}</div>
          <div class="error--text" v-if="errorMessage != null">{{ this.errorMessage }}</div>

          <interactive-auth ref="interactiveAuth" />

          <div class="create-buttons">
            <!-- Create button -->
            <v-btn :disabled="!formIsValid || creatingRoom" color="#6360F0" depressed @click.stop="handleNext"
              class="filled-button mt-4">
              <div v-if="creatingRoom" class="text-center">
                {{ creatingRoomStatus }}
                <v-progress-circular v-if="creatingRoom" :indeterminate="creatingRoomProgress == null"
                  :value="creatingRoomProgress" color="primary" width="2" size="20"></v-progress-circular>
              </div>
              <span v-else>{{ $t("getlink.next") }}</span>
            </v-btn>
            <v-btn v-if="!loggedIn" color="black" depressed text @click.stop="goToLoginPage" class="text-button">{{
              $t("menu.login")
              }}</v-btn>
          </div>
        </v-form>
      </div>
    </div>

    <div :class="{ 'toast-at-bottom': true, 'visible': showQRCopiedToast }">{{ $t("getlink.qr_image_copied") }}</div>
  </div>
</template>

<script>
import rememberMeMixin from "./rememberMeMixin";
import * as sdk from "matrix-js-sdk";
import logoMixin from "./logoMixin";
import InteractiveAuth from './InteractiveAuth.vue';
import util, { ROOM_TYPE_CHANNEL } from "../plugins/utils";
import YouAre from "../components/YouAre.vue";
import User from "../models/user";
import { CHANNEL_POWER_LEVELS } from "../services/matrix.service";
import createRoomMixin from "./createRoomMixin";
import CreateRoomAvatar from "./create/CreateRoomAvatar.vue";

export default {
  name: "CreateChannel",
  mixins: [rememberMeMixin, logoMixin, createRoomMixin],
  components: { InteractiveAuth, YouAre, CreateRoomAvatar },
  data() {
    return this.defaultData();
  },
  mounted() {
    this.$store.commit("setCurrentRoomId", null);
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
    currentUser() {
      return this.$store.state.auth.user;
    },
  },
  methods: {
    defaultData() {
      return {
        currentLoginServer: "",
        loadingLoginFlows: false,
        loginFlows: null,
        showQRCopiedToast: false,
      };
    },
    goToLoginPage() {
      this.$navigation.push({ name: "Login", params: { showCreateRoomOption: false, redirect: "CreateChannel" } }, 1);
    },
    preCreateRoom() {
      const prefix = this.$config.userIdPrefix;
      const user = new User(util.randomUser(prefix), util.randomPass(), false);
      const login = this.loggedIn ? Promise.resolve(this.currentUser) : this.loadLoginFlows().then(() => {
        return this.$store.dispatch("createUser", { user, registrationFlowHandler: this.$refs.interactiveAuth.registrationFlowHandler })
      });
      return login;
    },
    generateAliasForRoom() {
      return true;
    },
    getRoomCreationOptions() {
      const createRoomOptions = {
        visibility: "private", // Not listed!
        name: this.roomName,
        preset: "public_chat",
        initial_state:
          [
            {
              type: "m.room.history_visibility",
              state_key: "",
              content: {
                history_visibility: "shared"
              }
            },
            {
              type: "m.room.retention",
              state_key: "",
              content: {
                max_lifetime: 3600 * 24 * 7 * 1000
              }
            }
          ]
      };
      if (this.roomTopic && this.roomTopic.length > 0) {
        // Add topic
        createRoomOptions.topic = this.roomTopic;
      }

      createRoomOptions.creation_content = {
        type: ROOM_TYPE_CHANNEL
      }

      // Set power level event. Need to do that here, because we might not have the userId when the options object is created.
      const powerLevels = {};
      powerLevels[this.$matrix.currentUserId] = 100;
      let powerLevelContent = {
        users: powerLevels,
        events_default: 50,
        state_default: 50,
        events: CHANNEL_POWER_LEVELS
      }
      createRoomOptions.initial_state.push(
        {
          type: "m.room.power_levels",
          state_key: "",
          content: powerLevelContent
        });
      return createRoomOptions;
    },
    handleNext() {
      this.createRoom(this.$t("createchannel.status_creating"), this.$t("createchannel.error_channel"));
    },
    loadLoginFlows() {
      var user = Object.assign({}, this.user);
      return util.getMatrixBaseUrl(user, this.$config)
        .then((baseUrl) => {
          if (baseUrl !== this.currentLoginServer) {
            this.currentLoginServer = baseUrl;
            this.loadingLoginFlows = true;

            const matrixClient = sdk.createClient({ baseUrl: baseUrl });
            return matrixClient.loginFlows().then((response) => {
              console.log("FLOWS", response.flows);
              this.loginFlows = response.flows.filter(this.supportedLoginFlow);
              this.loadingLoginFlows = false;
              if (this.loginFlows.length == 0) {
                this.message = this.$t('login.no_supported_flow')
              } else {
                this.message = null;
              }
            });
          } else {
            return Promise.resolve();
          }
        })
    },
    supportedLoginFlow(flow) {
      return ["m.login.password"].includes(flow.type);
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/css/typography.scss";
@import "@/assets/css/create.scss";
</style>