<template>
  <div class="create-root fill-height">
    <div class="mt-4 d-flex flex-row align-center">
      <v-btn id="btn-back" text class="back-button" v-show="$navigation && $navigation.canPop()" @click.stop="goBack"
        :disabled="creatingRoom">
        <v-icon>arrow_back</v-icon>
      </v-btn>
      <YouAre class="flex-grow-1 text-end" v-if="loggedIn" />
    </div>
    <v-container fluid class="mt-40 px-4 text-center">
      <CreateRoomAvatar v-model="roomAvatar" />
      <div class="create-title h3-bold">{{ $t("new_room.title") }}</div>
      <div color="rgba(255,255,255,0.1)" class="text-center">
        <v-form v-model="formIsValid">
          <v-text-field v-model="roomName" :label="$t('new_room.name_room')" v-bind="{ ...roomNameInputFields }"
            v-on="{ ...roomNameInputListeners }"></v-text-field>
          <v-text-field v-model="roomTopic" :label="$t('new_room.room_topic')" v-bind="{ ...roomTopicInputFields }"
            v-on="{ ...roomTopicInputListeners }"></v-text-field>

          <!-- Message History (public room) -->
          <v-card class="ma-0 mt-10" flat>
            <v-card-text class="with-right-label">
              <div>
                <div class="paragraph-bold">{{ $t('room_info.message_history') }}</div>
                <div class="caption-1-gray">{{ $t('room_info.message_history_info') }}</div>
              </div>
              <v-switch class="ma-0" v-model="publicRoom"></v-switch>
            </v-card-text>
            <div class="option-warning" v-if="publicRoom"><v-icon size="18">$vuetify.icons.ic_warning</v-icon>{{
              $t("room_info.message_history_warning") }}</div>
          </v-card>

          <hr color="#ededed" />

          <!-- Limit History (message retention) -->
          <v-card class="ma-0 mt-2" flat>
            <v-card-text class="with-right-label">
              <div>
                <div class="paragraph-bold">{{ $t('room_info.message_retention') }}</div>
                <div class="caption-1-gray">{{ limitHistory ? $t('room_info.limit_history_info', {period:
                  roomMessageRetentionDisplayString(limitHistoryMilliseconds)}) : $t('room_info.message_retention_info')
                  }}</div>
                <div v-show="limitHistory" @click="showRetentionDialog"
                  class="paragraph paragraph-button mt-4 clickable">{{ $t('menu.edit') }}</div>
              </div>
              <v-switch class="ma-0" v-model="limitHistory"></v-switch>
            </v-card-text>
          </v-card>


          <div class="error--text" v-if="errorMessage != null">{{ this.errorMessage }}</div>

          <!-- Create button -->
          <v-btn :disabled="!formIsValid || creatingRoom" color="#6360F0" depressed @click.stop="onCreate"
            class="filled-button mt-4">
            <div v-if="creatingRoom && !enterRoomDialog" class="text-center">
              {{ creatingRoomStatus }}
              <v-progress-circular v-if="creatingRoom" :indeterminate="creatingRoomProgress == null"
                :value="creatingRoomProgress" color="primary" width="2" size="20"></v-progress-circular>
            </div>
            <span v-else>{{ $t("getlink.next") }}</span>
          </v-btn>
        </v-form>
      </div>

    </v-container>

    <interactive-auth ref="interactiveAuth" />

    <input id="user-avatar-picker" ref="useravatar" type="file" name="user-avatar"
      @change="handlePickedUserAvatar($event)" accept="image/*" class="d-none" />

    <v-dialog v-model="enterRoomDialog" :width="$vuetify.breakpoint.smAndUp ? '50%' : '90%'">
      <v-card>
        <v-container v-if="canEditProfile" class="pa-10">
          <v-row class="align-center">
            <v-col class="py-0">
              <div class="text-start font-weight-bold">{{ $t("join.choose_name") }}</div>
              <v-select ref="avatar" :items="availableAvatars" cache-items outlined dense @change="selectAvatar"
                :value="selectedProfile">
                <template v-slot:selection>
                  <v-text-field background-color="transparent" solo flat hide-details
                    @click.native.stop="(event) => event.target.focus()" @focus="$event.target.select()"
                    v-model="selectedProfile.name"></v-text-field>
                </template>
                <template v-slot:item="data">
                  <v-avatar size="32">
                    <v-img :src="data.item.image" />
                  </v-avatar>
                  <div class="ms-2">{{ data.item.name }}</div>
                </template>
              </v-select>
            </v-col>
            <v-col cols="2" class="py-0">
              <v-avatar @click="showUserAvatarPicker">
                <v-img v-if="selectedProfile" :src="selectedProfile.image" />
              </v-avatar>
            </v-col>
          </v-row>
          <v-row class="mt-0">
            <v-col class="py-0">
              <v-checkbox id="chk-remember-me" class="mt-0" v-model="rememberMe" @change="onRememberMe"
                :label="$t('join.remember_me')" />
            </v-col>
          </v-row>
          <v-row class="mt-0">
            <v-col class="py-0">
              <v-checkbox id="chk-accept-ua" class="mt-0" v-model="acceptUA">
                <template slot="label">
                  <i18n path="join.accept_ua" tag="span">
                    <template v-slot:agreement>
                      <a href="./ua.html" target="_blank" @click.stop>{{ $t("join.ua") }}</a>
                    </template>
                  </i18n>
                </template>
              </v-checkbox>
            </v-col>
          </v-row>
          <v-btn color="black" depressed class="filled-button" @click.stop="onEnterRoom"
            :disabled="!acceptUA || !selectedProfile.name">
            {{ $t("join.enter_room") }}
          </v-btn>
        </v-container>
      </v-card>
    </v-dialog>

    <MessageRetentionDialog :initialValue="limitHistoryMilliseconds" :show="selectRetentionDialog"
      v-on:close="selectRetentionDialog = false" v-on:message-retention-update="onUpdateHistoryLimit" />
  </div>
</template>

<script>
import util from "../plugins/utils";
import InteractiveAuth from './InteractiveAuth.vue';
import rememberMeMixin from "./rememberMeMixin";
import YouAre from "../components/YouAre.vue";
import createRoomMixin from "./createRoomMixin";
import roomRetentionMixin from "./roomRetentionMixin";
import CreateRoomAvatar from "./create/CreateRoomAvatar.vue";
import MessageRetentionDialog from "./MessageRetentionDialog.vue";

export default {
  name: "CreateRoom",
  components: { InteractiveAuth, YouAre, CreateRoomAvatar, MessageRetentionDialog },
  mixins: [rememberMeMixin, createRoomMixin, roomRetentionMixin],
  data() {
    return {
      availableAvatars: [],
      selectedProfile: {
        id: "",
        image: "",
        name: ""
      },
      enterRoomDialog: false,
      selectRetentionDialog: false,
      publicRoom: false,
      limitHistory: false,
      limitHistoryMilliseconds: 2 * 7 * 24 * 3600 * 1000, // 2 weeks default,
      acceptUA: false
    };
  },

  mounted() {
    this.$store.commit("setCurrentRoomId", null);
    this.availableAvatars = util.getDefaultAvatars();
    this.selectAvatar(
      this.availableAvatars[
      Math.floor(Math.random() * this.availableAvatars.length)
      ]
    );
  },

  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
    canEditProfile() {
      // If we have an account already, we can't edit profile here (need to go into profile view)
      if (this.currentUser) {
        return false;
      }
      return true;
    },
  },

  methods: {
    onCreate() {
      if (this.currentUser) {
        this.onEnterRoom();
      } else {
        this.enterRoomDialog = true;
      }
    },

    onEnterRoom() {
      this.enterRoomDialog = false;
      this.createRoom(this.$t("new_room.status_creating"), undefined);
    },

    preCreateRoom() {
      const hasUser = this.currentUser ? true : false;
      var setProfileData = false;

      return this.$matrix
        .getLoginPromise(this.$refs.interactiveAuth.registrationFlowHandler)
        .then((user) => {
          if (user.is_guest && !hasUser) {
            // Newly created account, joining first room.
            // Set avatar and display name to either the randomly chosen ones, or the
            // ones the users has changed to.
            setProfileData = true;

            // Set display name and avatar directly on the matrix object.
            if (
              this.selectedProfile.name &&
              this.selectedProfile.name.length > 0
            ) {
              this.$matrix.userDisplayName = this.selectedProfile.name;
            }
          }

          if (
            !setProfileData ||
            !this.selectedProfile.name ||
            this.selectedProfile.name.length == 0
          ) {
            return Promise.resolve(user);
          } else {
            console.log(
              "CreateRoom: Set display name to: " + this.selectedProfile.name
            );
            return this.$matrix.setUserDisplayName(this.selectedProfile.name);
          }
        }
        )
        .then(() => {
          if (!setProfileData || !this.selectedProfile.image) {
            console.log("CreateRoom: No avatar change");
            return Promise.resolve("no avatar");
          } else {
            console.log("CreateRoom: Updating avatar");
            return util.setAvatar(
              this.$matrix,
              this.selectedProfile.image,
              function (progress) {
                console.log("Progress: " + JSON.stringify(progress));
              }
            );
          }
        }
        );
    },
    generateAliasForRoom() {
      return this.publicRoom;
    },
    getRoomCreationOptions() {
      var createRoomOptions = {};
      if (this.publicRoom) {
        createRoomOptions = {
          visibility: "private", // Not listed!
          name: this.roomName,
          preset: "public_chat",
          initial_state: [
              {
                type: "m.room.history_visibility",
                state_key: "",
                content: {
                  history_visibility: "shared"
                }
              }
            ]
        };
      } else {
        createRoomOptions = {
          visibility: "private", // Not listed!
          name: this.roomName,
          preset: "public_chat",
          initial_state: [
            {
              type: "m.room.encryption",
              state_key: "",
              content: {
                algorithm: "m.megolm.v1.aes-sha2",
              },
            },
            {
              type: "m.room.guest_access",
              state_key: "",
              content: {
                guest_access: "forbidden",
              },
            },
            {
              type: "m.room.history_visibility",
              state_key: "",
              content: {
                history_visibility: "joined"
              }
            }
          ],
        };
      }
      if (this.roomTopic && this.roomTopic.length > 0) {
        // Add topic
        createRoomOptions.topic = this.roomTopic;
      }

      // Limit history?
      if (this.limitHistory && this.limitHistoryMilliseconds > 0) {
        createRoomOptions.initial_state.push({
          type: "m.room.retention",
          state_key: "",
          content: {
            max_lifetime: this.limitHistoryMilliseconds
          }
        });
      }

      // Set power level event. Need to do that here, because we might not have the userId when the options object is created.
      const powerLevels = {};
      powerLevels[this.$matrix.currentUserId] = 100;
      let powerLevelContent = {
        users: powerLevels,
        events_default: 0
      }
      createRoomOptions.initial_state.push(
        {
          type: "m.room.power_levels",
          state_key: "",
          content: powerLevelContent
        });
      return createRoomOptions;
    },

    selectAvatar(value) {
      this.selectedProfile = Object.assign({}, value); // Make a copy, so editing does not destroy data
    },

    showAvatarPickerList() {
      this.$refs.avatar.$refs.input.click();
    },

    /**
     * Show picker to select user avatar file
     */
    showUserAvatarPicker() {
      this.$refs.useravatar.click();
    },

    handlePickedUserAvatar(event) {
      util.loadAvatarFromFile(event, (image) => {
        this.selectedProfile.image = image;
      });
    },

    showRetentionDialog() {
      this.selectRetentionDialog = true;
    },

    onUpdateHistoryLimit(retention) {
      this.limitHistoryMilliseconds = retention;
      if (retention == 0) {
        this.limitHistory = false;
      }
    }

  }
};
</script>

<style lang="scss">
@import "@/assets/css/create.scss";
</style>