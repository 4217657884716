<template>
    <div class="create-room-avatar" color="#ededed">
        <v-img class="create-room-avatar__icon clickable" @click.stop="showRoomAvatarPicker" v-if="modelValue && modelValue.image" :src="modelValue.image" />
        <v-icon class="create-room-avatar__icon default" v-else>$vuetify.icons.room_avatar_placeholder</v-icon>
        <v-icon class="create-room-avatar__camera clickable" v-if="!modelValue || !modelValue.image" @click.stop="showRoomAvatarPicker">$vuetify.icons.ic_camera</v-icon>
        <input id="room-avatar-picker" ref="roomAvatar" type="file" name="roomAvatar"
            @change="handlePickedRoomAvatar($event)" accept="image/*" class="d-none" />
    </div>
</template>

<script>
export default {
    name: "CreateRoomAvatar",
    model: {
        prop: "modelValue",
        event: "update:modelValue",
    },
    props: {
        modelValue: {
            type: Object,
            default: function () {
                return null;
            },
        },
    },
    methods: {
        /**
        * Show picker to select room avatar file
        */
        showRoomAvatarPicker() {
            this.$refs.roomAvatar.click();
        },

        /**
         * Handle picked avatar
         */
        handlePickedRoomAvatar(event) {
            if (event.target.files && event.target.files[0]) {
                var reader = new FileReader();
                reader.onload = (e) => {
                    this.$emit('update:modelValue', {
                        image: e.target.result,
                        file: event.target.files[0]
                    });
                };
                reader.readAsDataURL(event.target.files[0]);
            }
        },
    }
};
</script>

<style lang="scss">
@import "@/assets/css/create.scss";

.create-room-avatar {
    width: 72px;
    height: 72px;
    position: relative;
    margin-left: auto;
    margin-right: auto;

    .create-room-avatar__icon {
        width: 100%;
        height: 100%;
        border-radius: 14px;
        &.default {
            background: $light-purple;            
        }
    }

    .create-room-avatar__camera {
        position: absolute;
        right: 0;
        bottom: 0;
    }
}
</style>