
export default {
    install(Vue, config) {

        const matomoService = new Vue({
            config: config,
            data() {
                return {
                    initialized: false
                }
            },
            created() {
                if (this.$options.config) {
                    const config = this.$options.config;
                    let server = config.server;
                    let siteId = config.siteId;
                    if (server && siteId) {
                        if (!server.endsWith("/")) {
                            server = server + "/";
                        }
                        let script = `
                          var _paq = window._paq = window._paq || [];
                          /* tracker methods like "setCustomDimension" should be called before "trackPageView" */
			  _paq.push(['trackPageView', ' ', window.location.hostname]);
                          _paq.push(['enableLinkTracking']);
                          (function() {
                            var u="${server}";
                            _paq.push(['setTrackerUrl', u+'matomo.php']);
                            _paq.push(['setSiteId', '${siteId}']);
                            var d=document, g=d.createElement('script'), s=d.getElementsByTagName('script')[0];
                            g.async=true; g.src=u+'matomo.js'; s.parentNode.insertBefore(g,s);
                          })();
                        `;
                        var s = document.createElement('script');
                        s.innerHTML = script;
                        document.body.appendChild(s);   
                        this.initialized = true;
                    }
                }
            },
            methods: {
                event(category, action) {
                    if (this.initialized) {
                        window._paq.push(['trackEvent', category, action]);
                    }
                }
            }
        });
        return matomoService;
    }
}
