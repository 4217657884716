import util from "../plugins/utils";

export default {
  data() {
    return {
      creatingRoom: false,
      creatingRoomStatus: null,
      creatingRoomProgress: null,
      formIsValid: false,
      roomName: "",
      roomTopic: "",
      roomAvatar: null,
      roomNameRules: [
        (v) => !!v || this.$t("create.field_required"),
        (v) => !v || v.length <= 50 || this.$t("new_room.room_name_limit_error_msg"),
        (v) => !v || !v.includes(":") || this.$t("new_room.colon_not_allowed"),
      ],
      roomTopicRules: [(v) => !v || v.length <= 500 || this.$t("create.topic_too_long")],
      errorMessage: null,
    };
  },
  computed: {
    roomNameInputFields() {
      return {
        color: "black",
        rules: this.roomNameRules,
        counter: "50",
        maxLength: "50",
        "background-color": "white",
        disabled: this.creatingRoom,
        //autofocus: true,
        solo: true,
        required: true,
      };
    },
    roomNameInputListeners() {
      return {
        keydown: () => (this.errorMessage = null),
        keyup: (e) => {
          if (e.keyCode === 13 && this.$refs.topic) {
            this.$refs.topic.focus();
          }
        },
      };
    },
    roomTopicInputFields() {
      return {
        ref: "topic",
        color: "black",
        rules: this.roomTopicRules,
        maxLength: "500",
        disabled: this.creatingRoom,
        filled: true,
        rounded: true,
        "hide-details": true,
      };
    },
    roomTopicInputListeners() {
      return {
        keydown: () => (this.errorMessage = null),
        keyup: (e) => {
          if (e.keyCode === 13 && this.$refs.create) {
            this.$refs.create.focus();
          }
        },
      };
    },
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
  },
  methods: {
    goBack() {
      if (!this.creatingRoom) {
        this.$navigation.pop();
      }
    },
    createRoom(creatingMessageString, errorMessageString) {
      // Reset errors
      this.errorMessage = null;
      this.creatingRoom = true;
      this.creatingRoomStatus = creatingMessageString;
      this.creatingRoomProgress = null;

      let roomId = "";

      this.preCreateRoom()
        .then(() => {
          let createRoomOptions = this.getRoomCreationOptions();
          if (this.generateAliasForRoom()) {
            // Promise to get a unique alias and use it in room creation options.
            //
            return util
              .getUniqueAliasForRoomName(
                this.$matrix.matrixClient,
                this.roomName,
                this.$matrix.currentUserMXDomain
              )
              .then((alias) => {
                createRoomOptions.room_alias_name = alias;
                return createRoomOptions;
              });
          } else {
            return Promise.resolve(createRoomOptions);
          }
        })
        .then((createRoomOptions) => {
          return this.$matrix.matrixClient
          .createRoom(createRoomOptions)
          .then(({ room_id }) => {
            roomId = util.sanitizeRoomId(room_id);
            if (createRoomOptions.room_alias_name) {
              const parts = roomId.split(":");
              if (parts && parts.length == 2) {
                roomId = "#" + createRoomOptions.room_alias_name + ":" + parts[1];
              }
            }
            if (!this.roomAvatar || !this.roomAvatar.file) {
              return true;
            }
            return util.setRoomAvatar(
              this.$matrix.matrixClient,
              room_id,
              this.roomAvatar.file,
              (p) => {
                this.creatingRoomStatus = this.$t("new_room.status_avatar");
                if (p.total) {
                  this.creatingRoomProgress = (100 * p.loaded) / p.total;
                } else {
                  this.creatingRoomProgress = null;
                }
              }
            );
          });
        })
        .then(() => {
          this.creatingRoomProgress = null;
          this.$navigation.push(
            {
              name: "Chat",
              params: { roomId: roomId },
            },
            -1
          );
        })
        .catch((error) => {
          this.errorMessage = errorMessageString || (error.data && error.data.error) || error.message || error.toString();
        })
        .finally(() => {
          this.creatingRoom = false;
          this.creatingRoomStatus = null;
        });
    },
  },
};
